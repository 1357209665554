import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VendorType } from "@utils/Constants";

interface IDataSlice {
  nhisCrawlInfo: {
    cookiesJSON: Optional<JSON>;
    additionalData: any;
  };

  analyzedData: any;
}

const initialState = (): IDataSlice => {
  return {
    nhisCrawlInfo: {
      cookiesJSON: null,
      additionalData: null,
    },
    analyzedData: null,
  };
};

export type DoAuthActionType = PayloadAction<{
  onSuccess: (param: any) => any;
  onError: (e: Error) => any;
  authType: VendorType;
  userInfo: {
    name: string;
    phone: string;
    birthday: string;
  };
}>;

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setNHISCrawlData: (
      state,
      action: PayloadAction<{
        cookiesJSON: Optional<JSON>;
        additionalData: any;
      }>
    ) => {
      state.nhisCrawlInfo.cookiesJSON = action.payload.cookiesJSON;
      state.nhisCrawlInfo.additionalData = action.payload.additionalData;
    },
    setAnalyzedData: (state, action) => {
      state.analyzedData = action.payload;
    },
    startAuth: (_, _action: DoAuthActionType) => {},
    afterAuth: (_, _action: DoAuthActionType) => {},
  },
});

export const { setNHISCrawlData, setAnalyzedData, startAuth, afterAuth } =
  dataSlice.actions;

export default dataSlice.reducer;

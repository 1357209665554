import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
} from "@redux-saga/core/effects";
import { RootState } from "@store";
import {
  DoAuthActionType,
  afterAuth,
  setAnalyzedData,
  setNHISCrawlData,
  startAuth,
} from "@store/slices/dataSlice";
import { Request } from "@utils";
import { ApiUrls } from "@utils/Constants";

function* startAuthSaga(action: DoAuthActionType) {
  yield all([
    put(
      setNHISCrawlData({
        cookiesJSON: null,
        additionalData: null,
      })
    ),
  ]);

  try {
    const result: {
      data: {
        cookieJarJSON: any;
        additionalData: any;
      };
    } = yield call(Request.post, ApiUrls.data.startAuth, {
      authType: action.payload.authType,
      userInfo: action.payload.userInfo,
    });
    yield put(
      setNHISCrawlData({
        cookiesJSON: result.data.cookieJarJSON,
        additionalData: result.data.additionalData,
      })
    );
    action.payload.onSuccess(null);
  } catch (err) {
    action.payload.onError(err as Error);
  }
}

function* afterAuthSaga(action: DoAuthActionType) {
  const { cookiesJSON, additionalData } = yield select(
    (state: RootState) => state.data.nhisCrawlInfo
  );

  try {
    const result: {
      data: any;
    } = yield call(Request.post, ApiUrls.data.afterAuth, {
      authType: action.payload.authType,
      userInfo: action.payload.userInfo,
      additionalData,
      cookiesJSON,
    });

    yield put(setAnalyzedData(result.data));

    action.payload.onSuccess(null);
  } catch (err) {
    action.payload.onError(err as Error);
  }
}

function* startAuthWatcher() {
  yield takeEvery(startAuth, startAuthSaga);
}

function* afterAuthWatcher() {
  yield takeEvery(afterAuth, afterAuthSaga);
}

export const dataSagas = [fork(startAuthWatcher), fork(afterAuthWatcher)];

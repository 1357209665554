import { fork, takeEvery } from "@redux-saga/core/effects";
import {
  ValidateCodeActionType,
  setCodeChecked,
  validateCode,
} from "@store/slices/userInfoSlice";
import { Request } from "@utils";
import { ApiUrls } from "@utils/Constants";
import { call, put } from "redux-saga/effects";

function* validateCodeSaga(action: ValidateCodeActionType) {
  const { code } = action.payload;

  try {
    const { data: isValidCode }: { data: boolean } = yield call(
      Request.post,
      ApiUrls.validate.validate,
      {
        code,
      }
    );

    yield put(
      setCodeChecked({
        code: isValidCode ? code : null,
      })
    );
  } catch (err) {
    console.error(err);
  }
}

function* validateCodeWatcher() {
  yield takeEvery(validateCode, validateCodeSaga);
}

export const sessionSagas = [fork(validateCodeWatcher)];

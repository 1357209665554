import React from "react";

import logoIcon from "@assets/images/icon_kakao_large.png";
import { AuthTemplate } from "@components/templates";
import { AuthStepDescription } from "@components/organisms/AuthStepView";
import { VendorType } from "@utils/Constants";

const authSteps: AuthStepDescription[] = [
  {
    title: "카카오톡 앱에서 인증요청 메시지 확인",
  },
  {
    title: "인증진행",
    sub: "비밀번호, 생체인식 등",
  },
  {
    title: "인증 완료 후 아래 ‘인증 완료’ 선택",
  },
];

const KakaoAuthPage = () => {
  return (
    <AuthTemplate
      authType={VendorType.Kakao}
      icon={logoIcon}
      title={`카카오 지갑 간편인증을\n진행해주세요`}
      authSteps={authSteps}
      pathStr="[카카오톡 앱 실행 → 대화 → 카카오톡 지갑]"
    />
  );
};

export default KakaoAuthPage;

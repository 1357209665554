const terms1 = `(주)렉스소프트(이하 "회사")는 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 관련 규정을 준수하며, 다음과 같이 개인정보 보호법 제30조에 따른 개인정보처리방침을 수립·공개하여 이용자 권익 보호에 최선을 다하고 있습니다.
본 개인정보처리방침은 회사가 제공하는 "웹 서비스 [건강성적표]" (이하 "서비스") 이용에 적용되며 다음과 같은 내용을 담고 있습니다.

1. 개인정보의 처리 목적
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
가. 건강성적표 서비스 회원 동의 및 관리
회원 동의의사 확인, 서비스 제공에 따른 본인 식별ㆍ인증, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지ㆍ통지, 고충처리 등

나. 건강성적표 서비스의 제공
건강성적표 서비스 제공, 콘텐츠 제공, 본인인증, 연령인증

다. 서비스향상 및 연구목적 활용
건강성적표 등 회원이 이용하는 건강성적표 서비스의 절차, 예측방법, 결과 분석 및 건강분석 연구에의 활용

라. 이벤트, 프로모션 및 모니터링 등
이벤트 응모 등 마케팅 목적의 프로모션, 안정적 서비스 운영을 위한 테스트 및 모니터링, 각종 건강 정보제공 및 신규 서비스 참여 제안 등
    

2. 개인정보 수집과 동의
회사는 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있습니다.
가. 개인정보를 수집하는 방법은 다음과 같습니다.
1) 회원 동의 및 서비스를 이용하는 과정에서 이용자가 개인정보 수집에 대하여 동의하고 직접 정보를 입력하는 경우
2) 연관된 서비스 또는 기관 등으로부터 개인정보를 제공받는 경우: (예)국민건강보험공단에서의 회원의 건강검진 데이터 등
3) 고객센터를 통한 상담과정에서 웹 서비스, 메일, 팩스, 전화 등 온 혹은 오프라인에서 진행되는 이벤트 및 프로모션 등의 참여

나. 건강성적표 서비스 이용과정에서 단말기 정보, IP주소, 쿠키, 방문일시, 서비스 이용기록 등의 정보가 자동적으로 생성되어 수집될 수 있습니다.

다. 개인정보 항목별 이용목적, 보유기간 등은 다음과 같습니다.`;

const terms2 = `라. 선택정보를 입력하지 않을 경우에도 기본 서비스 이용 여부에 대한 제한은 없습니다.

마. 회사는 만 18세 이상의 이용자에 한해서 서비스(회원동의 등)를 제공하는 것을 원칙으로 합니다.

3. 개인정보의 보유 및 이용기간
가. 회사는 회원이 회원자격을 유지하고 있는 동안 수집된 회원의 개인정보를 보유ㆍ이용할 수 있습니다.

나. 관계법령에 의해 회원의 정보를 보존하는 경우는 다음과 같습니다.`;

const terms3 = `4. 개인정보처리의 위탁
가. 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.`;

const terms4 = `나. 회사는 위탁계약 체결 시 개인정보보호법 25조에 따라 위탁업무수행 목적 외에 개인정보처리금지, 기술적 및 관리적 보호조치, 재위탁제한, 수탁자에 대한 관리와 감독, 손해배상 등 책임에 관한 사항을 계약서 등의 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독합니다.

다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보처리방침을 통하여 공개하도록 하겠습니다.

5. 개인정보의 파기절차 및 방법
가. 이용자의 개인정보는 원칙적으로 보유기간의 경과, 개인정보의 수집 및 이용목적 달성 등 그 개인정보가 불필요하게 되었을 때에는, 법령에 위반되지 않는 한, 지체 없이 파기합니다.

나. 이용자에게 1년간 건강성적표 서비스 이용기록이 없을 시, 개인정보 보호법 제39조의6에 근거하여 개인정보를 파기하거나 별도로 분리하여 저장 관리합니다

다. 파기절차
1) 회사는 개인정보의 파기에 관한 사항을 기록하고 관리하며, 파기는 개인정보 보호책임자의 책임하에 수행되며, 개인정보 보호책임자는 파기 결과를 확인합니다.
2) 다만, 다른 법령에 따라 보존해야 하는 경우에는 예외적으로 회원의 개인정보를 파기하지 않을 수 있습니다

라. 파기방법
1) 종이나 그 밖의 기록매체에 저장된 개인정보는 파쇄 하거나 소각합니다.
2) 전자적 파일 형태로 저장된 개인정보는 복원이 불가능한 방법 혹은 기록을 재생할 수 없는 기술적 방법으로 영구 삭제합니다.

마. 미 파기 정보의 보존방법
회사가 법령에 따라 개인정보를 파기하지 않고 보존하여야 하는 경우에는 해당 개인정보(파일 포함)를 분리하여 저장 관리하며, 이는 법률에 의한 경우가 아니 고서는 보존하는 이외의 다른 목적으로 이용하지 않습니다.

6. 정보주체와 법정대리인의 권리 의무 및 그 행사방법
가. 이용자는 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.

나. 위 가항의 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여도 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.

다. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.

라. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.

마. 서비스는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.

7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
회사는 회원들에게 특화된 맞춤서비스를 제공하기 위해서 회원들의 정보를 저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 회원의 컴퓨터 브라우저에게 보내는 소량의 정보이며 회원들의 PC(단말기) 컴퓨터내의 하드디스크에 저장되기도 합니다.
가. 쿠키의 사용 목적 : 회원들이 방문한 건강성적표 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 회원 규모 등을 파악하여 회원에게 최적화된 정보 제공을 위하여 사용합니다.

나. 쿠키의 설치/운영 및 거부 :
1) 회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
2) 쿠키 설정을 거부하는 방법으로는 회원이 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
3) 다만, 쿠키의 저장을 거부할 경우에는 건강성적표 서비스에서 로그인이 필요한 일부 서비스는 그 이용에 어려움이 있을 수 있습니다.

8. 행태 정보의 관리
가. 회사는 상품, 서비스 개발 및 고객 분석, 마케팅 등에 활용하기 위해 행태정보를 수집·이용하고 있습니다. 행태정보란 웹 방문 및 사용 이력, 검색 이력 등 고객 및 회원의 관심, 성향 등을 파악하고 분석할 수 있는 온라인 상의 이용자 활동 정보를 말합니다.

나. 회사는 다음과 같이 행태정보를 수집하고 있습니다.`;

const terms5 = `다. 회사는 웹 내에서 행해지는 고객의 주요 행동에 대해 로그를 남기는 방법으로 행태정보를 수집하고 있습니다.

라. 행태정보는 수집일로부터 3 년간 보유, 이용됩니다. 보유기간 경과 후에는 비식별화 조치 후 통계 업무 목적만을 위하여 보유, 이용됩니다.

마. 회사는 개인 맞춤형 서비스 제공에 필요한 최소한의 행태정보만을 수집하며, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.

9. 개인정보 보호책임자
가. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.`;

const terms6 = `나. 정보주체는 건강성적표 서비스를 이용하면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

다. 기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.
- 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
- 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
- 경찰청 사이버안전국 (police.go.kr / 국번없이 182)


10. 개인정보의 안정성 확보 조치
회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적·관리적 및 물리적 조치를 하고 있습니다. 단, 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대하여는 책임을 지지 않습니다. 이용자는 본인의 개인정보를 보호하기 위해서 자신의 ID와 비밀번호를 철저히 관리하고 책임을 져야 합니다.
가. 개인정보 취급 직원의 최소화 및 교육
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다. 개인정보를 취급하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에서 대한 정기적인 교육을 실시하고 있습니다. - 개인정보보호 포털 단체 온라인 교육 (https://www.privacy.go.kr/)

나. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

다. 해킹 등에 대비한 기술적 대책
해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.

라. 개인정보의 암호화
이용자의 개인정보는 암호화 솔루션을 통해 암호화되어 저장 및 관리되고 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.

마. 접속기록의 보관 및 위변조 방지
개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.

바. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.

사. 문서보안을 위한 잠금장치 사용
개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.

아. 비인가자에 대한 출입 통제
개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.

11. 개인정보 처리 방침의 변경 등
가. 본 개인정보 처리방침은 시행일로부터 적용되며, 관련 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 공지사항을 통하여 고지할 것입니다.

나. “회사”는 개인정보 처리방침을 변경하는 경우에 개인정보주체인 회원이 그 변경 여부, 변경된 사항의 시행시기와 변경된 내용을 언제든지 쉽게 알 수 있도록 지속적으로 서비스 웹을 통하여 공개합니다.

본 약관은 2023년 8월 1일부터 적용합니다.`;

const PrivacyPage = () => {
  return (
    <div className="terms-container">
      <b>개인정보처리방침</b>
      <p
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {terms1}
      </p>
      <table>
        <tr>
          <td style={{ width: "15%" }}>구분</td>
          <td style={{ width: "35%" }}>수집항목</td>
          <td style={{ width: "20%" }}>이용목적</td>
          <td style={{ width: "30%" }}>보유기간</td>
        </tr>
        <tr>
          <td>회원동의시(개인계정)</td>
          <td>
            [기본] 카카오, 네이버 계정 연동 시: 연동ID, 각 계정 토큰, 이메일
            <br />
            이름, 휴대폰번호, 생년월일
          </td>
          <td>회원동의 및 관리</td>
          <td rowSpan={3}>
            <u>
              회사는 회원의 정보를 안전하게 보관하며, 다음의 사유가 발생하면
              지체없이 파기합니다.
            </u>
            <br />
            <br />
            1. 회원 탈퇴 시<br />
            2. 서비스 종료 시<br />
            <br />
            단, 법령에 따라 일정 기간 정보보관 의무를 부과하는 경우에만 해당
            기간 동안 개인정보를 안전하게 보관합니다.
          </td>
        </tr>
        <tr>
          <td>
            건강성적표 서비스 이용 시<br />
            1)건강성적표
            <br />
            2)기타
          </td>
          <td>
            [기본] 회원동의시 입력한 정보 자동 수집
            <br />
            <br />
            [선택적] 국민건강보험공단에서 제공하는 국가건강검진결과데이터,
            병원진료내역, 약국조제내역, 접종내역 (회원이 건강성적표 웹에서 공유
            동의한 경우)
          </td>
          <td>건강성적표의 서비스 제공</td>
        </tr>
        <tr>
          <td>설문조사나 이벤트 참여, 마케팅 및 상품 안내 등</td>
          <td>이름, 주소, 연락처(전화번호)</td>
          <td>
            이벤트참여제안, 이벤트 마케팅 결과 재화 혹은 서비스 제공 및 안내
          </td>
        </tr>
        <tr>
          <td>1:1 문의</td>
          <td>이름, 휴대폰번호, 성별, 생년월일, 문의 내용, 문의 결과</td>
          <td>서비스, 오류, 제안, 기타문의 등</td>
          <td>해당 목적사업 완료 1년 이후까지</td>
        </tr>
      </table>
      <p
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {terms2}
      </p>
      <table>
        <tr>
          <td style={{ width: "30%" }}>관계법령</td>
          <td style={{ width: "50%" }}>개인정보</td>
          <td style={{ width: "20%" }}>보존기간</td>
        </tr>
        <tr>
          <td rowSpan={4}>전자상거래 등에서의 소비자 보호에 관한 법률</td>
          <td>계약 또는 청약철회 등에 관한 기록</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>대금결제 및 재화 등의 공급에 관한 기록</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
          <td>3년</td>
        </tr>
        <tr>
          <td>표시·광고에 관한 기록</td>
          <td>6개월</td>
        </tr>
        <tr>
          <td>국세기본법</td>
          <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>통신비밀보호법</td>
          <td>이용자의 인터넷 등 로그 기록</td>
          <td>3개월</td>
        </tr>
        <tr>
          <td>위치정보의 보호 및 이용 등에 관한 법률</td>
          <td>위치정보취급대장</td>
          <td>6개월</td>
        </tr>
      </table>
      <p
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {terms3}
      </p>
      <div style={{ textAlign: "center" }}>
        <table>
          <tr>
            <td>수탁자</td>
            <td>위탁업무 내용</td>
          </tr>
          <tr>
            <td style={{ padding: "0px 30px" }}>AWS 클라우드</td>
            <td style={{ padding: "0px 30px" }}>
              클라우드 서비스를 이용한 시스템 운영 및 데이터 보관
            </td>
          </tr>
        </table>
      </div>
      <p
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {terms4}
      </p>
      <table style={{ textAlign: "center" }}>
        <tr>
          <td style={{ width: "25%" }}>
            <b>수집하는 행태정보의 항목</b>
          </td>
          <td style={{ width: "25%" }}>
            <b>행태정보 수집 방법</b>
          </td>
          <td style={{ width: "25%" }}>
            <b>행태정보 수집 목적</b>
          </td>
          <td style={{ width: "25%" }}>
            <b>보유·이용기간 및 이후 정보처리 방법</b>
          </td>
        </tr>
        <tr>
          <td>
            이용자의 웹 서비스 방문이력, 사용이력(웹 클릭 내역, 입력 내용),
            검색이력
          </td>
          <td>이용자의 웹 방문/실행 시 자동 수집</td>
          <td>이용자의 관심, 성향에 기반한 개인 맞춤형 서비스를 제공</td>
          <td>수집일로부터 3년 후 파기</td>
        </tr>
      </table>
      <p
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {terms5}
      </p>
      <div style={{ textAlign: "center" }}>
        <table>
          <tr>
            <td>구분</td>
            <td>개인정보 보호 책임자</td>
          </tr>
          <tr>
            <td>이름</td>
            <td>권민석</td>
          </tr>
          <tr>
            <td style={{ padding: "0px 15px" }}>소속/직위</td>
            <td style={{ padding: "0px 15px" }}>데이터사이언스팀/개발이사</td>
          </tr>
          <tr>
            <td>전화번호</td>
            <td>070-5001-3939</td>
          </tr>
          <tr>
            <td>이메일</td>
            <td>minseok@rexsw.com</td>
          </tr>
        </table>
      </div>
      <p
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {terms6}
      </p>
    </div>
  );
};

export default PrivacyPage;

import { useAppSelector } from "@store";

const useUserInfo = () => {
  const data = useAppSelector((state) => {
    return {
      name: state.userInfo.name,
      phone: state.userInfo.phone,
      birthday: state.userInfo.birthday,
      code: state.userInfo.code,
      codeChecked: state.userInfo.codeChecked,
    };
  });

  return data;
};

export default useUserInfo;

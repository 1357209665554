import React from "react";
import styled from "styled-components";

interface ButtonProps {
  bgColor: string;
  color: string;
  children: React.ReactNode;
  onClick?: () => any;
  className?: string;
  style?: React.CSSProperties;
}

const Container = styled.div`
  height: 48px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const Button = function (props: ButtonProps) {
  const { bgColor, color, onClick, children, className, style, ...rest } =
    props;

  return (
    <Container
      className={className}
      style={{
        backgroundColor: bgColor,
        color,
        ...(style ?? {}),
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default Button;

import React from "react";
import { Colors } from "@utils";
import Modal from "react-modal";

import loadCompletedImage from "@assets/images/image_completed.png";
import { Button } from "@components/atoms";

interface ModalProps extends Modal.Props {
  onOK: () => any;
}

const LoadCompletedDialog = (props: ModalProps) => {
  const { onOK, ...rest } = props;

  return (
    <Modal {...rest}>
      <div className="flex flex-col items-center justify-center w-[318px] p-[20px]">
        <div
          className="text-center text-[16px] font-[600] leading-[24px] mb-[10px]"
          style={{ color: Colors.totallyBlack }}
        >
          데이터 불러오기가 완료되었어요!
          <br />
          건강성적을 바로 받아볼 수 있습니다 ✏
        </div>
        <img className="w-[207px] h-[121px]" src={loadCompletedImage} alt="" />
        <Button
          style={{ marginTop: 13, width: "100%" }}
          bgColor={Colors.darkBlue}
          color={Colors.white}
          onClick={onOK}
        >
          건강성적표 확인하기
        </Button>
      </div>
    </Modal>
  );
};

export default LoadCompletedDialog;

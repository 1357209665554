import React from "react";

import offIcon from "@assets/images/checkbox_off.png";
import onIcon from "@assets/images/checkbox_on.png";
import Row from "./Row";
import { Colors } from "@utils";

interface CheckboxProps {
  checked: boolean;
  bold?: boolean;
  onChange?: (yn: boolean) => any;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const Checkbox = function (props: CheckboxProps) {
  const { checked, bold, onChange, style, children } = props;

  return (
    <Row style={style} onClick={() => (onChange ? onChange(!checked) : null)}>
      <img
        className="w-[20px] h-[20px] mr-[10px] cursor-pointer"
        src={checked ? onIcon : offIcon}
        alt=""
      />
      <span
        className={bold ? "text-[14px] font-[600]" : "text-[14px] font-[400]"}
        style={{
          color: Colors.totallyBlack,
          cursor: "pointer",
        }}
      >
        {children}
      </span>
    </Row>
  );
};

export default Checkbox;

import React from "react";
import logoImage from "@assets/images/logo.png";
import { Colors } from "@utils";

const KakaoInAppBrowserPage = () => {
  return (
    <div className="flex flex-col py-[22px] min-h-full">
      <div className="text-center">
        <img
          src={logoImage}
          alt=""
          className="block w-[242px] h-[51px] mb-[32px] mx-auto"
        />
        <div
          className="block text-[20px] font-[600] mb-[36px] leading-[30px]"
          style={{ color: Colors.totallyBlack }}
        >
          카카오 브라우저에서는
          <br />
          사용할 수 없습니다
        </div>
        <div
          className="block text-[15px] font-[500] leading-[18px]"
          style={{ color: Colors.darkGray }}
        >
          크롬, 사파리 등 외부 브라우저에서
          <br />
          링크를 열어주세요
        </div>
      </div>
    </div>
  );
};

export default KakaoInAppBrowserPage;

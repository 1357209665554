export enum VendorType {
  Naver = 1,
  Kakao = 2,
}

export const ApiUrls = {
  prefix: "api/",
  data: {
    startAuth: "data/start_auth",
    afterAuth: "data/after_auth",
  },
  validate: {
    validate: "validate/",
  },
};

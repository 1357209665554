import React from "react";
import { Colors, Validator } from "@utils";
import styled from "styled-components";
import { Button, Input } from "@components/atoms";
import { useAppDispatch } from "@store";
import { setUserInfo, validateCode } from "@store/slices/userInfoSlice";
import { useHistory } from "react-router-dom";
import { TopArea } from "@components/organisms";
import { useUserInfo } from "@store/hooks";

import queryString from "query-string";

const InputTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${Colors.darkGray};
  margin-bottom: 10px;
`;

function InputInfoPage() {
  const [name, setName] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [birthday, setBirthday] = React.useState<string>("");

  const dispatch = useAppDispatch();
  const history = useHistory();

  const queryCode =
    (queryString.parse(history.location.search)
      .code as unknown as Optional<string>) ?? "";

  React.useEffect(() => {
    dispatch(validateCode({ code: queryCode }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { code, codeChecked } = useUserInfo();

  const onSubmit = () => {
    if (name.trim().length === 0) {
      alert("이름을 입력해주세요");
      return;
    }

    if (!Validator.isPhoneNumber(phone)) {
      alert("유효한 전화번호를 입력해주세요");
      return;
    }

    if (!Validator.isBirthday(birthday)) {
      alert("유효한 생년월일을 입력해주세요");
      return;
    }

    dispatch(
      setUserInfo({
        name: name.trim(),
        phone,
        birthday,
      })
    );

    history.push({ pathname: "/agree", search: history.location.search });
  };

  if (!codeChecked) return null;

  if (!code) {
    return (
      <div className="flex flex-col p-[22px] min-h-full">
        <span className="text-center text-[15px] font-[700] mt-[50px]">
          유효하지 않은 링크입니다
        </span>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-[22px] min-h-full">
      <TopArea />
      <span
        className="text-[15px] font-[700] leading-[26px] mt-[20px] mb-[22px]"
        style={{ color: Colors.totallyBlack }}
      >
        개인정보 입력
      </span>
      <InputTitle>이름</InputTitle>
      <Input
        style={{ marginBottom: 12 }}
        placeholder="이름을 입력하세요."
        maxLength={10}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
      />
      <InputTitle>전화번호</InputTitle>
      <Input
        style={{ marginBottom: 12 }}
        mask="999-9999-9999"
        maskPlaceholder="_"
        placeholder="000-0000-0000"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPhone(e.target.value)
        }
      />
      <InputTitle>생년월일</InputTitle>
      <Input
        mask="999999"
        maskPlaceholder=""
        placeholder="생년월일 6자리를 입력하세요."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setBirthday(e.target.value)
        }
      />
      <Button
        className="mt-[40px]"
        bgColor={Colors.darkBlue}
        color={Colors.white}
        onClick={onSubmit}
      >
        확인
      </Button>
    </div>
  );
}

export default InputInfoPage;

import React from "react";
import Routers from "@routes";
import { Provider as StoreProvider } from "react-redux";
import { store } from "@store";
import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { KakaoInAppBrowserPage } from "@components/pages";

const Container = styled.div`
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
`;

function App() {
  const [isKakaoInAppBrowser, setIsKakaoInAppBrowser] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf("kakaotalk") > -1) {
      setIsKakaoInAppBrowser(true);
      window.location.href =
        "kakaotalk://web/openExternal?url=" +
        encodeURIComponent(window.location.href);
      setTimeout(() => {
        window.location.href = /iPad|iPhone|iPod/i.test(agent)
          ? "kakaoweb://closeBrowser"
          : "kakaotalk://inappbrowser/close";
      }, 1000);
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <Container>
        {isKakaoInAppBrowser ? (
          <KakaoInAppBrowserPage />
        ) : (
          <Router>
            <Routers />
          </Router>
        )}
      </Container>
    </StoreProvider>
  );
}

export default App;

import React from "react";
import logoImage from "@assets/images/logo.png";
import { Colors } from "@utils";

interface TopAreaProps {
  style?: React.CSSProperties;
}

const TopArea = function (props: TopAreaProps) {
  const { style, ...rest } = props;
  return (
    <div className="text-center" style={style} {...rest}>
      <img
        src={logoImage}
        alt=""
        className="block w-[242px] h-[51px] mb-[20px] mx-auto"
      />
      <span
        className="block text-[20px] font-[700] mb-[31px]"
        style={{ color: Colors.darkBlue }}
      >
        <span className="text-[24px] font-[800]">건강나이</span> 궁금하시지
        않으신가요?
      </span>
      <span
        className="block text-[15px] font-[500] leading-[26px]"
        style={{ color: Colors.black }}
      >
        서울대학교 보건환경연구소에서 개발한
        <br />
        AI분석으로 건강나이를 확인해 보세요.
      </span>
    </div>
  );
};

export default TopArea;

import { Button, Row } from "@components/atoms";
import { AgreeArea, TopArea } from "@components/organisms";
import { Colors } from "@utils";
import React from "react";
import styled from "styled-components";

import kakaoIcon from "@assets/images/icon_kakao.png";
import naverIcon from "@assets/images/icon_naver.png";
import { VendorType } from "@utils/Constants";
import { useUserInfo } from "@store/hooks";
import { useAppDispatch } from "@store";
import { startAuth } from "@store/slices/dataSlice";
import { useHistory } from "react-router";

const Divider = styled.div`
  height: 8px;
  background: ${Colors.gray3};
`;

const BottomArea = styled.div`
  padding: 15px 20px;
  padding-bottom: 0px;
`;

const SocialAuthRow = styled(Row)`
  padding-left: 14px;
  padding-right: 44px;
  width: 100%;
  justify-content: space-between;
`;

function AgreementPage() {
  const userInfo = useUserInfo();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const [allAgreed, setAllAgreed] = React.useState<boolean>(false);

  const onClickSocialAuth = (authType: VendorType) => {
    if (!allAgreed) {
      alert("필수 항목에 동의해주세요");
      return;
    }

    dispatch(
      startAuth({
        userInfo,
        authType,
        onSuccess: () => {
          if (authType === VendorType.Naver) {
            history.push({
              pathname: "/naver_auth",
              search: history.location.search,
            });
          } else {
            history.push({
              pathname: "/kakao_auth",
              search: history.location.search,
            });
          }
        },
        onError: (e: Error) => {
          if (e.message === "NO MATCHING USER") {
            alert("이름, 전화번호, 생일을 확인해주세요");
          } else {
            alert("오류가 발생했습니다");
          }
        },
      })
    );
  };

  return (
    <div className="flex flex-col py-[22px] min-h-full">
      <TopArea style={{ marginBottom: 46 }} />
      <Divider />
      <BottomArea>
        <AgreeArea onValidityChanged={(allAgreed) => setAllAgreed(allAgreed)} />
        <Button
          className="mt-[30px]"
          bgColor="#FEE500"
          color={Colors.totallyBlack}
          onClick={() => onClickSocialAuth(VendorType.Kakao)}
        >
          <SocialAuthRow>
            <img src={kakaoIcon} className="w-[30px] h-[30px]" alt="" />
            <div className="text-[16px] font-[500]">카카오톡 인증하기</div>
            <div />
          </SocialAuthRow>
        </Button>
        <Button
          className="mt-[14px]"
          bgColor="#03C75A"
          color={Colors.white}
          onClick={() => onClickSocialAuth(VendorType.Naver)}
        >
          <SocialAuthRow>
            <img src={naverIcon} className="w-[30px] h-[30px]" alt="" />
            <div className="text-[16px] font-[500]">네이버 인증하기</div>
            <div />
          </SocialAuthRow>
        </Button>
        <div
          className="flex flex-col items-end text-[14px] font-[500] mt-[14px]"
          style={{ color: Colors.gray1 }}
        >
          인증 수단을 선택하세요.
        </div>
      </BottomArea>
    </div>
  );
}

export default AgreementPage;

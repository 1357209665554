const terms = `
제1조 (목적)
본 약관은 ㈜렉스소프트(이하 ”회사”)가 운영하는 건강성적표 서비스 (이하 ”건강성적표”)의 이용과 관련하여 “회사”와 “회원” 간의 권리· 의무 및 책임 사항을 규정함을 목적으로 합니다.
    

제2조 (정의)
본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
“건강성적표”란, 회사가 모바일 웹에서 회원의 동의에 기반하여 그 회원에게 제공하는 다양한 건강 관련 서비스로서 그 내용은 건강 나이, 건강 성적 제공 등을 의미합니다.
“회원"이란 건강성적표 서비스를 이용하기 위하여 본 약관에 동의한 자로서 회사로부터 본 서비스를 이용할 수 있는 자격을 보유한 자를 말합니다.
“이용자”란 건강성적표 웹을 이용하는 자로서 “회원” 자격 유무를 불문하고, 당해 웹을 이용하는 경우라면 모두 이용자에 포함됩니다.
”건강성적표”란, 회사가 건강성적표 서비스를 제공하는 모바일 웹을 말합니다.
   

제3조 (약관 등의 명시와 설명 및 개정)
회사는 본 약관을 회원들이 알 수 있도록 관련 건강성적표 웹 페이지 내에 게시합니다.
회사는 불가피한 사정이 있는 경우 관계법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
회사는 사정상 변경의 경우와 영업상 중요 사유가 발생한 경우에는 이 약관을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통보함으로써 효력이 발생합니다.
본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 관계법령 또는 상관례에 따릅니다.
    

제4조 (건강성적표 서비스의 내용 및 범위)
회사는 건강성적표 웹 상에서, 회원이 그 전달에 특별히 동의한다면, 회사는 그 회원의 "건강검진 데이터"를 전달받아 다음과 같은 서비스를 제공하고 있습니다. 
가) 건강성적표 정보 제공 (회원의 건강검진 데이터를 기반으로 산출)(국민건강보험공단과 인증이 가능한 경우)
나) 기타 "회사"가 정하는 서비스
    

제5조 (서비스 내용에 따른 개별 동의 여부)
① 회원이 건강성적표 서비스를 제공받기 위하여는 회원의 개인인증서로 인증절차를 거쳐 건강보험공단의 데이터를 회사에 제공하는 동의가 필요합니다.
② 기타 서비스 제공상 개인의 개별동의가 필요한 사항에 대하여는 별도로 동의 여부를 확인하는 절차를 거치게 됩니다.
    

제6조 (서비스의 이용 및 변경과 중단)
① 회원은 회사가 별도로 고지한 서비스에 있어서 이용약관, 개인정보처리방침 및 기타 회사가 공지하는 사항을 항상 숙지하여야 하며, 이를 숙지하지 못하여 생기는 불이익은 회원에게 있습니다.
② 회사는 그 판단에 따라 필요한 서비스의 내용을 변경할 수 있습니다. 이 경우에는 변경된 서비스의 내용 및 제공일자를 30일 전부터 공지합니다. 단, 변동내용을 구체적으로 공지하기가 불가능한 경우에는 그 취지를 현재의 서비스를 게시한 곳에 공지합니다.
③ 회사는 다음 각 호의 1에 해당하는 사유가 발생한 경우 서비스의 일부 또는 전부를 중단할 수 있습니다.
1. 천재지변, 전쟁, 폭동, 화재, 파업 등 쟁의행위, 정부기관의 통제 기타 회사의 합리적인 노력으로 제어할 수 없는 사유가 발생하거나 발생할 우려가 있는 경우
2. 기간통신사업자로부터 전기통신서비스가 제공되지 않은 경우
3. 관련 시스템을 포함한 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등의 사유가 발생한 경우
4. 서비스가 제3자와의 제휴를 통하여 제공되는 경우에 당해 제휴사업자의 사정에 따라 변경되거나 중지되는 경우
5. 기타 서비스의 원활한 운영을 현저히 저해하는 사유가 발생한 경우
④ 제2항 또는 제3항의 사유로 서비스 내용이 변경 또는 중단되는 경우, 회사는 그로 인해 회원이 입은 손해에 대하여 고의 또는 중과실이 없는 한 배상하지 아니합니다.
    

제7조 (책임제한 및 면책)
① 건강성적표 서비스에서 제공되는 건강 관련 콘텐츠는, 회원이 제공한 정보에 기반한 개략적이며 일반적인 내용으로써 어떠한 경우라도 의학적 진단, 진료 혹은 치료를 대신할 수 없습니다.
② 건강성적표 서비스 과정에서 제공되는 건강성적표, 건강관리와 관련된 계획과 제안 및 정보 등은 통상적인 정보에 기반한 일반적 권고사항에 불과한 것으로 개인의 건강상태에 따라 달라질 수 있으며, 이 내용의 활용여부는 전적으로 회원의 판단에 따르는 것으로 회사는 이에 대하여 어떠한 책임도 지지 않습니다.
③ 서비스에서 제공되는 결과는 결코 의학적 진단, 진료, 혹은 치료를 대신하려는 목적이 아니기 때문에, 회원의 건강상태에 관한 의문점이나 걱정이 있다면 실제 전문의사를 찾아 진단을 받아야 합니다.
④ 회사는 건강 관련 콘텐츠에서 언급된 어떠한 특정한 검사나 제품 혹은 치료법도 추천하지 않습니다.
⑤ 회사에서 제공하는 건강 관련 정보는 전적으로 회원이 제공하는 한정된 정보에 따라 회사의 컴퓨터의 알고리즘을 통하여 정리되거나 통계자료를 기반으로 한 예측 수치입니다. 이러한 정보는 그 회원의 판단에 따라 이용되는 것일 뿐이며, 회원의 판단에 따른 사항에 대하여 회사는 어떠한 책임도 지지 않습니다.
⑥ 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써 발생하는 피해에 대하여 회사는 일체의 책임을 지지 않습니다.
⑦ 회사는 회원이 건강성적표 웹에 제공한 정보, 자료, 사실의 신뢰도, 정확성 등 그 내용에 관하여는 책임을 지지 않습니다.
    


제 8조 (정보의 제공)
① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 문자메시지(SMS) 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 문자 메시지 등에 대해서 수신 거절을 할 수 있습니다.
② 제1항의 정보를 문자메시지(SMS) 등을 통하여 전송하려고 하는 경우에는 회원의 사전 동의를 받아서 전송합니다. 다만, 고객문의 등에 대한 회신에 있어서는 그러하지 않습니다.
    

제 9조 (광고 게재 및 광고주와의 거래)
①회사는 건강성적표 웹 및 사이트, 서신 등에 광고를 게재할 수 있습니다. 회원은 건강성적표 서비스 이용 시 노출되는 광고게재에 대해 동의하는 것으로 간주됩니다. 광고를 수신한 회원은 수신거절을 회사에 대하여 할 수 있습니다.
② 광고주에 의해 게재된 광고내용이나 판촉활동에 대하여 회원은 본인의 책임과 판단으로 참여하거나 교신 또는 거래하여야 하며, 그 결과로써 발생하는 모든 손실 또는 손해에 대하여 회사는 책임을 지지 않는 것을 원칙으로 합니다.
    

제 10조 (개인정보 보호)
① 회사는 회원의 개인정보를 수집, 이용하는 때에는 당해 회원에게 그 목적을 고지하고 동의를 받습니다.
② 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용, 제공 단계에서 당해 “회원”에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
③ 회사는 제1항과 제2항에 의해 회원의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련 사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령이 정한 사항을 미리 명시하거나 고지해야 하며, “회원”은 언제든지 이 동의를 철회할 수 있습니다. 
④ 회사는 회원의 개인정보의 보안에 대하여 기술적 안전 조치를 강구하고 관리에 만전을 기함으로써 사용자의 정보보안을 위하여 노력합니다.
⑤ 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
    

제 11조 (회원의 의무)
①회원은 건강성적표 서비스를 통하여 얻은 자료 혹은 정보에 대하여는 스스로의 선택과 책임하에 이를 이용하여야 합니다.
②회원은 다음 각 호의 행위를 하여서는 안됩니다.
가) 회원신청 시 허위내용을 등록하는 행위
나) 회사 및 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위
다) 다른 회원의 정보를 도용하는 행위
라) 회사가 게시한 정보의 변경
마) 다른 회원에 대한 개인정보를 동의 없이 수집, 저장, 공개하는 행위
바) 회사가 제공하는 서비스에 정한 약관 혹은 기타 서비스 이용에 관한 규정을 위반하는 행위
③ 제2항에 해당하는 행위를 한 회원이 있을 경우 회사는 사용자의 회원자격을 적절한 방법으로 제한 및 정지, 상실 시킬 수 있습니다.
    

제 12조 (회사의 의무)
① 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 본 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공하기 위해서 노력합니다.
② 회사는 “회원”이 안전하게 건강성적표 서비스를 이용할 수 있도록 회원의 개인정보보호를 위한 보안 시스템을 갖추어야 합니다.
③ 회사는 건강성적표 서비스 이용과 관련하여 발생하는 “회원”의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.
④ 회사는 건강성적표 서비스 이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 알림 기능 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.
    

제 13조 (서비스의 저작권 및 이용제한)
① 회원이 건강성적표 서비스 웹에 게시한 게시물 등으로 인하여 본인 또는 타인에게 손해나 기타 문제가 발생하는 경우 회원은 이에 대한 책임을 지며, "회사"는 특별한 사정이 없는 한 이에 대하여 책임을 지지 않습니다.
② 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속되며, 회원은 건강성적표 서비스 웹을 이용하여 얻은 정보를 회사의 사전 승낙없이 복제, 송신, 출판, 배포, 방송, 가공, 판매하는 행위 등 서비스 내에 게재된 자료를 상업적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
③ 회원은 자신이 게시한 게시물을 회사가 국내외에서 다음 각 호의 목적으로 사용하는 것을 허락합니다.
1. 웹 내에서 회원 게시물의 복제, 전송, 전시, 배포 및 우수 게시물을 서비스 화면에 노출하기 위하여 회원 게시물의 크기를 변화하거나 단순화하는 등의 방식으로 수정하는 것
2. 회사가 운영하는 관련 웹의 서비스 내에서 회원 게시물을 전시•배포하는 것. 단 회원이 전시•배포에 대하여 반대의 의사를 회사에게 통지할 경우 그 이후부터 회사는 관련 웹 및 사이트의 서비스 내에서 당해 회원의 게시물을 전시•배포하지 않습니다.
3. 회사를 홍보하기 위한 목적으로 미디어, 통신사 등에게 이용자의 게시물 내용을 보도•방영하게 하는 것. 단 이 경우 게시물 내용이 회원의 신상정보가 노출되고 회원의 명예를 손상시킬 우려가 있는 경우라면 회사는 회원으로부터 보도•방영에 대한 사전 동의를 득하기로 합니다.
④ 회사는 회사의 합병, 영업양도, 회사가 운영하는 웹 및 사이트 간의 통합 등의 사유로 원래의 게시물의 내용을 변경하지 않고 게시물의 게시 위치를 변경할 수 있습니다.
⑤ 회원은 건강성적표 웹을 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하여서는 안됩니다.
    

제 14조 (손해배상)
① 회사는 건강성적표 서비스 이용과 관련하여 회원에게 발생한 손해에 대하여 회사의 고의 또는 중과실의 위법행위로 인하여 발생한 경우가 아닌 한 책임을 지지 않습니다.
② 회원은 본 약관을 위반함으로 인하여 발생한 회사나 제 3자에 대한 손해에 대하여 그 배상을 할 책임을 부담합니다.
    

제 15조 (분쟁의 해결)
① 회사와 회원은 건강성적표 서비스와 관련하여 발생한 분쟁을 신의성실에 입각하여 원활하게 해결할 수 있도록 최선의 노력을 다 하여야 합니다
② 회사는, 건강성적표 서비스의 이용과 관련하여 회원으로부터 제기되는 불만사항 및 의견을 합리적으로 원활하게 처리할 수 있도록 최선의 노력을 다하여야 합니다.
    

제 16조 (관련 법규의 준용)
본 약관에서 정하지 않은 사항은 별도의 약정이 없는 한 관련 법규, 관련 약관 혹은 특별한 상호 약정에 따르며, 이에 정하는 바가 없는 경우에는 일반적인 상관례에 의합니다.
    

제 17조 (준거법 및 관할 법원)
회사와 회원 간에 소송이 제기되는 경우에 준거법은 대한민국법으로 하며, 민사소송법상 관할 소재지 법원을 그 관할법원으로 합니다.
    

본 약관은 2023년 8월 1일부터 적용합니다.
`;

const TermsPage = () => {
  return (
    <div className="terms-container">
      <b>건강성적표 서비스 이용약관</b>
      <p
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {terms}
      </p>
    </div>
  );
};

export default TermsPage;

import axios, { AxiosRequestConfig } from "axios";

import qs from "qs";

import { ApiUrls } from "./Constants";

export enum RequestTypes {
  Api,
  Extern,
}

export default class Request {
  static API_HOST = process.env.REACT_APP_API_HOST;

  static parseQs(url: string) {
    try {
      let q = url.substring(url.indexOf("?"));
      const hashIdx = q.indexOf("#");
      if (hashIdx > -1) {
        q = q.substring(0, hashIdx);
      }
      return qs.parse(q, {
        ignoreQueryPrefix: true,
      });
    } catch (err) {
      console.error(err);
      return {};
    }
  }

  static getUrl(_url: string, addPrefix: boolean): string {
    const endpoint = (addPrefix ? ApiUrls.prefix : "") + _url;
    const url = `${Request.API_HOST}${endpoint}`;
    return url;
  }

  static addQueryString(url: string, params: any): string {
    const paramsString = qs.stringify(params, { encodeValuesOnly: true });
    if (paramsString.length > 0) {
      return `${url}?${paramsString}`;
    }
    return url;
  }

  static async _request(
    method: string,
    _url: string,
    params: any,
    type: RequestTypes,
    _headers: any
  ): Promise<any> {
    let url, headers;

    if (type === RequestTypes.Extern) {
      url = _url;
      headers = _headers;
    } else {
      url = await Request.getUrl(_url, true);
    }

    if (method === "GET") {
      url = Request.addQueryString(url, params);
    }

    const options: AxiosRequestConfig = {
      method,
      url,
      headers,
    };

    if (type === RequestTypes.Api) {
      options.withCredentials = true;
    }

    if (method !== "GET") options["data"] = params;

    return axios(options)
      .then(function (response: any) {
        return response.data;
      })
      .catch(function (error: any) {
        if (error.response) {
          const err = new Error(error.response.data?.message);
          err.status = error.response.status;
          throw err;
        } else {
          if (error.code === "ERR_NETWORK") {
            throw new Error("네트워크 오류가 발생했습니다");
          } else {
            throw error;
          }
        }
      });
  }

  static get(
    url: string,
    params: any = {},
    type: RequestTypes = RequestTypes.Api,
    headers: any = null
  ): Promise<any> {
    return Request._request("GET", url, params, type, headers);
  }

  static post(
    url: string,
    params: any = {},
    type: RequestTypes = RequestTypes.Api,
    headers: any = null
  ): Promise<any> {
    return Request._request("POST", url, params, type, headers);
  }

  static put(
    url: string,
    params: any = {},
    type: RequestTypes = RequestTypes.Api,
    headers: any = null
  ): Promise<any> {
    return Request._request("PUT", url, params, type, headers);
  }

  static delete(
    url: string,
    params: any = {},
    type: RequestTypes = RequestTypes.Api,
    headers: any = null
  ): Promise<any> {
    return Request._request("DELETE", url, params, type, headers);
  }
}

import DateUtil from "./DateUtil";

class Validator {
  static isPhoneNumber(text: string) {
    const regex = /^01[0-9]-[0-9]{4}-[0-9]{4}$/;
    return regex.test(text);
  }

  static isBirthday(text: string) {
    try {
      const regex = /^[0-9]{2}[0-9]{2}[0-9]{2}$/;
      if (!regex.test(text)) return false;

      const date = DateUtil.convertToDate(text);
      if (!DateUtil.isValidDate(date)) return false;

      const today = new Date();
      if (today.getTime() < date.getTime()) return false;

      if (date.getFullYear() < 1910) return false;

      return true;
    } catch (err) {
      // invalid date
      return false;
    }
  }
}

export default Validator;

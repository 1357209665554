import { Colors } from "@utils";
import React from "react";
// @ts-ignore
import html2canvas from "html2canvas";

import reportBar from "@assets/images/report_bar.png";

import healthAgeIcon from "@assets/images/icon_healthage.png";

import healthAgeBestIcon from "@assets/images/icon_healthage_best.png";
import healthAgeGoodIcon from "@assets/images/icon_healthage_good.png";
import healthAgeNormalIcon from "@assets/images/icon_healthage_normal.png";
import healthAgeBadIcon from "@assets/images/icon_healthage_bad.png";

import expectedAgeIcon from "@assets/images/icon_expected_age.png";

import heartIcon from "@assets/images/icon_heart.png";
import pancreasIcon from "@assets/images/icon_pancreas.png";
import vesselIcon from "@assets/images/icon_vessel.png";
import liverIcon from "@assets/images/icon_liver.png";
import kidneyIcon from "@assets/images/icon_kidney.png";
import fatIcon from "@assets/images/icon_fat.png";

interface ReportViewProps {
  name: string;
  analyzedData: any;
  onGetDataURL: (dataURL: string) => any;
}

const ReportView = function (props: ReportViewProps) {
  const { name, analyzedData, onGetDataURL } = props;

  const rendered = React.useRef<boolean>(false);

  React.useLayoutEffect(() => {
    if (!rendered.current) {
      rendered.current = true;
      html2canvas(document.querySelector("#report-view")!, {
        scale: 3,
      }).then((canvas: any) => {
        onGetDataURL(canvas.toDataURL("image/png"));
      });
      return;
    }
  });

  const getColor = (grade: HealthGrade) => {
    switch (grade) {
      case "최우수":
        return "#D6DE00";
      case "우수":
        return "#3BB58F";
      case "보통":
        return "#1597B8";
      case "미흡":
        return "#4C55A1";
      default:
        return "#4C55A1";
    }
  };

  const renderGradeItem = (
    grade: HealthGrade,
    sideLabel: string,
    currentGrade: HealthGrade
  ) => {
    const selected = grade === currentGrade;

    return (
      <div
        style={{
          background: selected ? getColor(grade) : Colors.white,
          color: selected ? Colors.white : Colors.totallyBlack,
          height: 16,
          paddingLeft: 3,
          paddingRight: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            fontSize: 9,
            fontWeight: 500,
            marginRight: 5,
          }}
        >
          {grade}
        </span>
        <span
          style={{
            fontSize: 7,
            fontWeight: 300,
          }}
        >
          {sideLabel}
        </span>
      </div>
    );
  };

  const getAgeIcon = (grade: HealthGrade) => {
    switch (grade) {
      case "최우수":
        return healthAgeBestIcon;
      case "우수":
        return healthAgeGoodIcon;
      case "보통":
        return healthAgeNormalIcon;
      case "미흡":
        return healthAgeBadIcon;
      default:
        return healthAgeBadIcon;
    }
  };

  const renderAgeItem = (label: string, icon: string, value: number) => {
    return (
      <div
        style={{
          width: 85,
          border: 0.5,
          borderColor: "#3BB58F",
          borderStyle: "solid",
          borderRadius: 8,
          paddingTop: 9,
          paddingBottom: 6,
          background: "#FAFAFA",
          textAlign: "center",
        }}
      >
        <div
          style={{
            color: Colors.darkGray,
            fontSize: 13,
            fontWeight: 700,
            marginBottom: 1,
          }}
        >
          {label}
        </div>
        <img
          style={{
            width: 24,
            height: 24,
          }}
          src={icon}
          alt=""
        />
        <div
          style={{
            color: Colors.darkGray,
            fontSize: 13,
            fontWeight: 500,
            marginTop: 1,
          }}
        >
          {value}세
        </div>
      </div>
    );
  };

  const renderPartAgeItem = (
    name: string,
    value: number,
    grade: HealthGrade,
    icon: string
  ) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: 10,
            fontWeight: 300,
            color: Colors.totallyBlack,
            marginBottom: 6,
          }}
        >
          {name}
        </div>
        <img
          style={{
            width: 30,
            height: 30,
          }}
          src={icon}
          alt=""
        />
        <div
          style={{
            marginTop: 4,
            fontSize: 12,
            fontWeight: 600,
            color: getColor(grade),
          }}
        >
          {grade}
        </div>
        <div
          style={{
            marginTop: 4,
            fontSize: 12,
            fontWeight: 600,
            color: getColor(grade),
          }}
        >
          {value}세
        </div>
      </div>
    );
  };

  const getSummaryText = (grade: HealthGrade) => {
    if (grade === "최우수" || grade === "우수") {
      return [
        "동일연령 대비 ",
        "우수한 건강성적",
        "을 가지고 계시네요. 지금처럼 건강한 때야 말로 건강 위험을 대비하기 좋은 시기입니다.",
      ];
    } else if (grade === "보통") {
      return [
        "동일연령 대비 ",
        "평균적인 건강상태를 유지",
        "하시고 계시네요. 앞으로의 건강 위험를 대비하기 위한 방안이 필요합니다.",
      ];
    } else {
      return [
        "종합 성적은 미흡으로, 동일연령 대비 ",
        "건강성적이 낮은 편",
        "이시네요. 지금부터는 미리미리 건강 위험에 대비하셔야 합니다.",
      ];
    }
  };

  if (rendered.current) return null;

  return (
    <div
      id="report-view"
      style={{
        position: "absolute",
        left: 99999,
        width: 340,
        border: "3px solid #DCDDDD",
        paddingTop: 11,
        paddingBottom: 10,
        paddingLeft: 9,
        paddingRight: 9,
      }}
    >
      <div
        style={{
          color: Colors.black,
          fontSize: 24,
          fontWeight: 800,
          textAlign: "center",
        }}
      >
        건강성적표
      </div>
      <img
        src={reportBar}
        style={{
          width: "100%",
          height: 5,
          marginTop: 6,
          marginBottom: 11,
        }}
        alt=""
      />
      <div
        style={{
          paddingLeft: 6,
          paddingRight: 6,
        }}
      >
        <div
          style={{
            color: Colors.darkGray,
            fontSize: 15,
            fontWeight: 700,
          }}
        >
          종합 평가
        </div>
        <div
          style={{
            marginTop: 1,
            height: 1.5,
            background: "#DCDDDD",
          }}
        />
        <div
          style={{
            background: Colors.lightGray,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 14,
            paddingRight: 11,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 56,
              height: 56,
              border: 3,
              borderColor: getColor(analyzedData["종합등급"]),
              color: getColor(analyzedData["종합등급"]),
              borderStyle: "solid",
              borderRadius: 28,
              background: Colors.white,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 20,
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
          >
            {analyzedData["종합등급"]}
          </div>
          <div
            style={{
              marginLeft: 10,
              flex: 1,
              lineHeight: "25px",
              fontSize: 15,
              fontWeight: 600,
              color: Colors.darkGray,
            }}
          >
            <span>건강점수: {analyzedData["종합점수"]}점</span>
            <br />
            <span>
              건강등수: {analyzedData["종합점수등수"]}/
              <span
                style={{
                  color: "#B1B1B1",
                }}
              >
                100
              </span>
            </span>
          </div>
          <div>
            {renderGradeItem("최우수", "90점 이상", analyzedData["종합등급"])}
            {renderGradeItem("우수", "80점 이상", analyzedData["종합등급"])}
            {renderGradeItem("보통", "65점 이상", analyzedData["종합등급"])}
            {renderGradeItem("미흡", "64점 이하", analyzedData["종합등급"])}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
            marginTop: 9,
          }}
        >
          <span
            style={{
              color: Colors.darkGray,
              fontSize: 15,
              fontWeight: 700,
            }}
          >
            건강나이
          </span>
          <span
            style={{
              color: Colors.gray1,
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {new Date(analyzedData["검사일"]).getFullYear()}년 기준 실제나이{" "}
            {analyzedData["나이"]}세
          </span>
        </div>
        <div
          style={{
            marginTop: 1,
            marginBottom: 10,
            height: 1.5,
            background: "#DCDDDD",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {renderAgeItem("실제나이", healthAgeIcon, analyzedData["나이"])}
          {renderAgeItem(
            "건강나이",
            getAgeIcon(analyzedData["종합등급"]),
            analyzedData["건강나이"]
          )}
          {renderAgeItem("기대수명", expectedAgeIcon, analyzedData["기대수명"])}
        </div>
        <div
          style={{
            color: Colors.darkGray,
            fontSize: 15,
            fontWeight: 700,
            marginTop: 15,
          }}
        >
          신체 건강 성적
        </div>
        <div
          style={{
            marginTop: 1,
            height: 1.5,
            background: "#DCDDDD",
          }}
        />
        <div
          style={{
            background: Colors.lightGray,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 3,
            paddingRight: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {renderPartAgeItem(
            "심장",
            analyzedData["심장나이"],
            analyzedData["심장등급"],
            heartIcon
          )}
          {renderPartAgeItem(
            "췌장",
            analyzedData["췌장나이"],
            analyzedData["췌장등급"],
            pancreasIcon
          )}
          {renderPartAgeItem(
            "혈관",
            analyzedData["혈관나이"],
            analyzedData["혈관등급"],
            vesselIcon
          )}
          {renderPartAgeItem(
            "간",
            analyzedData["간나이"],
            analyzedData["간등급"],
            liverIcon
          )}
          {renderPartAgeItem(
            "신장",
            analyzedData["신장나이"],
            analyzedData["신장등급"],
            kidneyIcon
          )}
          {renderPartAgeItem(
            "비만",
            analyzedData["비만나이"],
            analyzedData["비만등급"],
            fatIcon
          )}
        </div>
      </div>
      <div
        style={{
          borderRadius: 999,
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 25,
          paddingRight: 25,
          background: "rgba(19, 78, 191, 0.1)",
          marginTop: 11,
          fontSize: 14,
          fontWeight: 500,
          color: Colors.darkGray,
        }}
      >
        <span style={{ fontWeight: 700 }}>{name}</span>
        <span>님은 </span>
        {getSummaryText(analyzedData["종합등급"]).map((txt, i) => (
          <span
            key={`summary-${i}`}
            style={{
              fontWeight: i === 1 ? 700 : 500,
            }}
          >
            {txt}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ReportView;

import {
  AgreementPage,
  InputInfoPage,
  KakaoAuthPage,
  NaverAuthPage,
  ResultPage,
  Terms,
} from "@components/pages";
import React from "react";

export interface RouteProps {
  path: string;
  component: React.FC;
  sessionRequired?: boolean;
}

const Routes: Array<RouteProps> = [
  {
    path: "/",
    component: InputInfoPage,
  },
  {
    path: "/terms/terms",
    component: Terms.TermsPage,
  },
  {
    path: "/terms/privacy",
    component: Terms.PrivacyPage,
  },
  {
    path: "/terms/sensitive",
    component: Terms.SensitivePage,
  },
  {
    path: "/agree",
    component: AgreementPage,
    sessionRequired: true,
  },
  {
    path: "/naver_auth",
    component: NaverAuthPage,
    sessionRequired: true,
  },
  {
    path: "/kakao_auth",
    component: KakaoAuthPage,
    sessionRequired: true,
  },
  {
    path: "/result",
    component: ResultPage,
    sessionRequired: true,
  },
  {
    path: "/*",
    component: InputInfoPage,
    sessionRequired: true,
  },
];

export default Routes;

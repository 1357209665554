const SensitivePage = () => {
  return (
    <div className="terms-container">
      <b>민감정보 수집 및 이용 동의</b>
      <p>
        건강성적표에서는 건강 검진 결과를 보관, 조회 및 활용하여 맞춤형 건강
        정보 및 증진 서비스 이용을 위해서 민감정보 수집 및 이용에 대한 동의가
        필요합니다. 본 서비스와 관련하여 “회원”은 민감정보 수집 및 이용에 대해
        동의를 거부하실 수 있습니다. 동의를 거부할 경우, 관련 서비스를 이용하실
        수 없습니다.
      </p>
      <table>
        <tr>
          <td style={{ width: "150px" }}>수집 및 이용 항목</td>
          <td>
            - 국가검진/진료 데이터: 검진 대상자, 건강 검진 결과, 진료 및
            투약정보, 건강검진 사용자 연동
            <br />* 정보주체별 수집하는 정보가 상이할 수 있음
          </td>
        </tr>
        <tr>
          <td>수집 및 이용 목적</td>
          <td>
            검진 대상자 확인, 본인 진료/투약 정보, 검진 결과, 맞춤형 건강 정보
            및 증진 서비스 제공을 위한 활용
          </td>
        </tr>
        <tr>
          <td>보유 이용 기간</td>
          <td>
            “회사”는 원칙적으로 회원의 정보를 다음의 사유 발생 시 지체없이
            파기하고 있습니다.
            <br />
            1. 1년간 서비스 이용기록이 없을 시<br />
            2. 서비스 종료 시
          </td>
        </tr>
        <tr>
          <td>동의 시 이용가능한 서비스</td>
          <td>건강성적표 서비스</td>
        </tr>
      </table>
    </div>
  );
};

export default SensitivePage;

import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Colors } from "@utils";
import { Row } from "@components/atoms";

export interface AuthStepDescription {
  title: string;
  sub?: string;
}

interface AuthStepViewProps {
  authSteps: AuthStepDescription[];
  style?: CSSProperties;
}

const LeftBorder = styled.div`
  align-self: stretch;
  background: rgba(19, 78, 191, 0.25);
  width: 2px;
  border-radius: 1px;
`;

const AuthStepView = function (props: AuthStepViewProps) {
  const { authSteps, style, ...rest } = props;

  const renderStep = (authStep: AuthStepDescription, i: number) => {
    return (
      <div
        key={`step_${i}`}
        style={{ marginBottom: i === authSteps.length - 1 ? 0 : 15 }}
      >
        <div
          className="text-[15px] font-[500]"
          style={{ color: Colors.darkGray }}
        >
          {i + 1}. {authStep.title}
        </div>
        {authStep.sub && (
          <div
            className="text-[13px] font-[400] mt-[3px]"
            style={{
              color: Colors.gray1,
            }}
          >
            {authStep.sub}
          </div>
        )}
      </div>
    );
  };

  const renderSteps = () => {
    return authSteps.map(renderStep);
  };

  return (
    <Row style={style} {...rest}>
      <LeftBorder style={{ marginRight: 12 }} />
      <div>{renderSteps()}</div>
    </Row>
  );
};

export default AuthStepView;

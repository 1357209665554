import React from "react";
import { Colors } from "@utils";
import { useUserInfo } from "@store/hooks";
import AuthStepView, {
  AuthStepDescription,
} from "@components/organisms/AuthStepView";
import { Button, Row } from "@components/atoms";
import warnIcon from "@assets/images/icon_warn.png";
import styled from "styled-components";
import { LoadCompletedDialog, LoadingDialog } from "@components/modals";
import { VendorType } from "@utils/Constants";
import { useAppDispatch } from "@store";
import { afterAuth } from "@store/slices/dataSlice";
import { useHistory } from "react-router-dom";

interface AuthTemplateProps {
  authType: VendorType;
  icon: string;
  title: string;
  authSteps: AuthStepDescription[];
  pathStr: string;
  helpStrs?: string[];
}

const HelpList = styled.ul`
  list-style: disc;
  list-style-position: inside;
  padding-left: 8px;
  color: ${Colors.gray1};
`;

const AuthTemplate = function (props: AuthTemplateProps) {
  const { authType, icon, title, authSteps, pathStr, helpStrs } = props;

  const userInfo = useUserInfo();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [showCompletedDialog, setShowCompletedDialog] =
    React.useState<boolean>(false);

  const loading = React.useRef<boolean>(false);

  const onPressAuth = () => {
    if (loading.current) return;

    loading.current = true;
    setShowLoader(true);

    dispatch(
      afterAuth({
        authType,
        userInfo,
        onSuccess: () => {
          loading.current = false;
          setShowLoader(false);
          setShowCompletedDialog(true);
        },
        onError: (err) => {
          loading.current = false;
          setShowLoader(false);
          alert(err.message ?? "오류가 발생했습니다");
        },
      })
    );
  };

  const gotoNextPage = () => {
    setShowCompletedDialog(false);
    history.push({ pathname: "/result", search: history.location.search });
  };

  return (
    <div className="px-[20px] pt-[50px] pb-[37px]">
      <img src={icon} className="w-[48px] h-[48px] mb-[25px]" alt="" />
      <div
        className="whitespace-pre-wrap text-[20px] font-[600] leading-[30px]"
        style={{
          color: Colors.totallyBlack,
        }}
      >
        {title}
      </div>
      <div
        className="text-[14px] font-[500] mt-[8px] mb-[30px]"
        style={{ color: Colors.gray1 }}
      >
        {userInfo.name}님의 휴대폰으로 인증 요청을 보냈습니다.
      </div>
      <AuthStepView authSteps={authSteps} />
      <div className="mt-[44px]">
        <Row className="mb-[12px]">
          <img src={warnIcon} className="w-[24px] h-[24px] mr-[10px]" alt="" />
          <div
            className="text-[16px] font-[600]"
            style={{ color: Colors.black }}
          >
            인증요청 알림이 오지 않나요?
          </div>
        </Row>
        <HelpList>
          <li>
            <b>{pathStr}</b>에서 인증 요청 내용을 확인할 수 있어요.
          </li>
          {helpStrs && helpStrs.length > 0
            ? helpStrs.map((helpStr, i) => (
                <li
                  key={`help_str_${i}`}
                  style={{
                    marginTop: 15,
                  }}
                >
                  {helpStr}
                </li>
              ))
            : null}
        </HelpList>
      </div>
      <Button
        style={{ marginTop: 74 }}
        bgColor={Colors.darkBlue}
        color={Colors.white}
        onClick={onPressAuth}
      >
        인증 완료
      </Button>
      <LoadingDialog text="내역을 불러오는중 입니다." isOpen={showLoader} />
      <LoadCompletedDialog onOK={gotoNextPage} isOpen={showCompletedDialog} />
    </div>
  );
};

export default AuthTemplate;

import React from "react";
import { Colors } from "@utils";
import { Oval } from "react-loader-spinner";
import Modal from "react-modal";

interface ModalProps extends Modal.Props {
  text: string;
}

const LoadingDialog = (props: ModalProps) => {
  const { text, ...rest } = props;

  return (
    <Modal {...rest}>
      <div className="flex flex-col items-center justify-center w-[318px] h-[160px]">
        <Oval
          height={48}
          width={48}
          color={Colors.darkBlue}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor={Colors.white}
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
        <div
          className="text-[14px] font-[500] mt-[10px]"
          style={{ color: Colors.gray1 }}
        >
          {text}
        </div>
      </div>
    </Modal>
  );
};

export default LoadingDialog;

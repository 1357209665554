const colors = {
  white: "#FFFFFF",
  totallyBlack: "#000000",
  black: "#121212",
  darkGray: "#434343",
  darkBlue: "#134EBF",
  gray1: "#9399A4",
  gray2: "#F3F3F3",
  gray3: "#F5F5F5",
  gray4: "#EDEDED",
  lightGray: "#F6F7F7",
};

export default colors;

import { Button } from "@components/atoms";
import { ReportView } from "@components/organisms";
import React from "react";

import { useAppSelector } from "@store";
import { Colors, saveImage } from "@utils";
import { useUserInfo } from "@store/hooks";

const ResultPage = () => {
  const userInfo = useUserInfo();
  const analyzedData = useAppSelector((state) => state.data.analyzedData);

  const [dataURL, setDataURL] = React.useState<Optional<string>>(null);

  return (
    <div className="pt-[8px] px-[20px] pb-[30px]">
      <div
        className="text-[13px] font-[500] leading-[16px] text-center mb-[9px]"
        style={{
          color: Colors.darkGray,
        }}
      >
        건강성적표는 고객님의 건강검진 기록을 토대로
        <br />
        건강 정도를 성적표 형태로 AI 분석한 결과입니다.{" "}
      </div>
      {dataURL ? <img src={dataURL} alt="" /> : null}
      <ReportView
        name={userInfo.name}
        analyzedData={analyzedData}
        onGetDataURL={setDataURL}
      />
      {dataURL ? (
        <Button
          bgColor={Colors.darkBlue}
          color={Colors.white}
          style={{ marginTop: 13 }}
          onClick={() => saveImage(dataURL)}
        >
          이미지 저장
        </Button>
      ) : null}
    </div>
  );
};

export default ResultPage;

import { Colors } from "@utils";
import React from "react";
import styled from "styled-components";
// @ts-ignore
import InputMask from "react-input-mask";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  mask?: string;
  maskPlaceholder?: string;
  alwaysShowMask?: boolean;
}

const InputContainer = styled.div`
  background: ${Colors.gray2};
  border-radius: 4px;
`;

const Input = function (props: InputProps) {
  const { style, mask, maskPlaceholder, alwaysShowMask, ...rest } = props;

  return (
    <InputContainer style={style}>
      <InputMask
        className="info-input"
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        alwaysShowMask={alwaysShowMask}
        {...rest}
      />
    </InputContainer>
  );
};

export default Input;

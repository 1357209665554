import { Checkbox, Row } from "@components/atoms";
import { Colors } from "@utils";
import React from "react";
import styled from "styled-components";

import gotoAgreeIcon from "@assets/images/chevron_right.png";

interface AgreeAreaProps {
  style?: React.CSSProperties;
  onValidityChanged?: (valid: boolean, checks: boolean[]) => any;
}

const Container = styled.div`
  padding: 0px 10px;
`;

const Divider = styled.div`
  height: 1px;
  background: ${Colors.gray4};
`;

const AgreeRow = styled(Row)`
  height: 28px;
  justify-content: space-between;
`;

const GotoAgreeImg = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const AgreeArea = function (props: AgreeAreaProps) {
  const { style, onValidityChanged } = props;

  const [agree1, setAgree1] = React.useState<boolean>(false);
  const [agree2, setAgree2] = React.useState<boolean>(false);
  const [agree3, setAgree3] = React.useState<boolean>(false);

  const allChecked = React.useMemo(
    () => agree1 && agree2 && agree3,
    [agree1, agree2, agree3]
  );

  const prevAllChecked = React.useRef<boolean>(allChecked);

  const toggleAll = (yn: boolean) => {
    if (yn) {
      setAgree1(true);
      setAgree2(true);
      setAgree3(true);
    } else {
      setAgree1(false);
      setAgree2(false);
      setAgree3(false);
    }
  };

  const openTermsPage = (pageName: string) => {
    window.open(`/terms/${pageName}`, "_blank");
  };

  React.useEffect(() => {
    if (prevAllChecked.current !== allChecked) {
      prevAllChecked.current = allChecked;
      if (onValidityChanged) {
        onValidityChanged(allChecked, [agree1, agree2, agree3]);
      }
    }
  }, [onValidityChanged, allChecked, agree1, agree2, agree3]);

  return (
    <Container style={style}>
      <Checkbox
        style={{ marginLeft: 11 }}
        bold
        checked={allChecked}
        onChange={toggleAll}
      >
        전체 내용에 동의합니다.
      </Checkbox>
      <Divider
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      />
      <div className="pl-[11px]">
        <div className="text-[14px] font-[400] mb-[10px]">
          간편인증 서비스 이용에 대한 동의
        </div>
        <AgreeRow style={{ marginBottom: 5 }}>
          <Checkbox checked={agree1} onChange={setAgree1}>
            개인정보 수집 및 이용 동의 (필수)
          </Checkbox>
          <GotoAgreeImg
            src={gotoAgreeIcon}
            onClick={() => openTermsPage("privacy")}
          />
        </AgreeRow>
        <AgreeRow style={{ marginBottom: 5 }}>
          <Checkbox checked={agree2} onChange={setAgree2}>
            민감정보 수집 및 이용 동의 (필수)
          </Checkbox>
          <GotoAgreeImg
            src={gotoAgreeIcon}
            onClick={() => openTermsPage("sensitive")}
          />
        </AgreeRow>
        <AgreeRow>
          <Checkbox checked={agree3} onChange={setAgree3}>
            건강성적표 서비스 이용약관 (필수)
          </Checkbox>
          <GotoAgreeImg
            src={gotoAgreeIcon}
            onClick={() => openTermsPage("terms")}
          />
        </AgreeRow>
      </div>
    </Container>
  );
};

export default AgreeArea;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtil } from "@utils";

type UserInfoSliceState = {
  name: string;
  phone: string;
  birthday: string;

  code: Optional<string>;
  codeChecked: boolean;
};

const initialState: UserInfoSliceState = {
  name: "",
  phone: "",
  birthday: "",

  code: null,
  codeChecked: false,
};

export type SetUserInfoActionType = PayloadAction<{
  name: string;
  phone: string;
  birthday: string;
}>;

export type ValidateCodeActionType = PayloadAction<{
  code: string;
}>;

const userInfoSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUserInfo: (state, action: SetUserInfoActionType) => {
      const { name, phone, birthday } = action.payload;

      state.name = name;
      state.phone = phone.replace(/-/g, "");
      state.birthday = DateUtil.getDateString(DateUtil.convertToDate(birthday));
    },
    validateCode: (_, _action: ValidateCodeActionType) => {},
    setCodeChecked: (
      state,
      action: PayloadAction<{
        code: Optional<string>;
      }>
    ) => {
      state.code = action.payload.code;
      state.codeChecked = true;
    },
  },
});

export const { setUserInfo, validateCode, setCodeChecked } =
  userInfoSlice.actions;

export default userInfoSlice.reducer;

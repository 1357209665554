import React from "react";

import logoIcon from "@assets/images/icon_naver_large.png";
import { AuthTemplate } from "@components/templates";
import { AuthStepDescription } from "@components/organisms/AuthStepView";
import { VendorType } from "@utils/Constants";

const authSteps: AuthStepDescription[] = [
  {
    title: "네이버 앱에서 인증요청 메시지 확인",
  },
  {
    title: "인증진행",
    sub: "비밀번호, 생체인식 등",
  },
  {
    title: "인증 완료 후 아래 ‘인증 완료’ 선택",
  },
];

const NaverAuthPage = () => {
  return (
    <AuthTemplate
      authType={VendorType.Naver}
      icon={logoIcon}
      title={`네이버 앱 간편인증을\n진행해주세요`}
      authSteps={authSteps}
      pathStr="[네이버 앱 실행 → ‘종 아이콘' → 네이버 인증서]"
      helpStrs={[
        "네이버 앱이 설치돼있는지, 알림 수신동의 되어 있는지 확인해주세요.",
      ]}
    />
  );
};

export default NaverAuthPage;

// @ts-ignore
import dateformat from "dateformat";

class DateUtil {
  static isValidDate(d: Date) {
    return d instanceof Date && !Number.isNaN(d.getTime());
  }

  static getYYYYMMDD(date: Date): string {
    return dateformat(date, "yyyymmdd");
  }

  static getDateString(date: Date): string {
    return dateformat(date, "yyyy-mm-dd");
  }

  static convertToDate(dateString: string): Date {
    const currentYear = new Date().getFullYear() - 2000;

    const year = Number(dateString.slice(0, 2));
    const month = Number(dateString.slice(2, 4)) - 1;
    const day = Number(dateString.slice(4, 6));
    const date = new Date(
      (currentYear > year ? 2000 : 1900) + year,
      month,
      day
    );

    if (month !== date.getMonth() || day !== date.getDate())
      throw new Error("invalid date");

    return date;
  }
}

export default DateUtil;

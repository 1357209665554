import React from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import Routes from "./routes";
import { useUserInfo } from "@store/hooks";
import styled from "styled-components";
import backIcon from "@assets/images/chevron_left.png";

const BackIcon = styled.img`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
`;

const Routers = () => {
  const userInfo = useUserInfo();

  const location = useLocation();

  const history = useHistory();

  return (
    <>
      <Switch>
        {Routes.map((route) => (
          <Route
            exact
            key={route.path}
            path={route.path}
            render={(props) => {
              if (route.sessionRequired) {
                if (userInfo.name.length > 0) {
                  return <route.component />;
                } else {
                  return (
                    <Redirect
                      to={{ pathname: "/", search: history.location.search }}
                    />
                  );
                }
              } else {
                return <route.component />;
              }
            }}
          />
        ))}
      </Switch>
      {location.pathname === "/" ? null : (
        <BackIcon src={backIcon} onClick={() => history.goBack()} />
      )}
    </>
  );
};

export default Routers;
